<template>
  <div class="container mx-auto px-5 py-10 space-y-3" v-if="!hasVerifiedEmail">
    <p class="text-2xl font-extrabold">
      {{ $t("dashboard.verify_your_email_address") }}
    </p>
    <p
      v-html="
        $t('dashboard.check_email_for_verify', { email: user?.email ?? '' })
      "
    ></p>
    <p>
      {{ $t("dashboard.didnt_get_email") }}
      <app-button-outline
        :loading="loading"
        @onClick="resendVerificationEmail"
        class="border-gray-400 text-gray-600 ml-1"
      >
        {{ $t("general.resend") }}
      </app-button-outline>
    </p>
  </div>
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.getters["authStore/user"];
    },
    hasVerifiedEmail() {
      return this.$store.getters["authStore/hasVerifiedEmail"];
    },

    loading() {
      return this.$store.getters["authStore/loading"];
    },

    message() {
      return this.$store.getters["authStore/message"];
    },
  },
  methods: {
    async resendVerificationEmail() {
      this.resetState();

      const resendStoreResult = await this.$store.dispatch(
        "authStore/resendVerificationEmail"
      );

      if (resendStoreResult == null) {
        this.$notify(
          {
            group: "error",
            title: this.message ?? this.$t("general.email_error"),
          },
          5000
        );
        return;
      }

      this.$notify(
        {
          group: "success",
          title: this.$t("general.email_sent"),
        },
        5000
      );
    },

    resetState() {
      this.$store.commit("authStore/resetState", {
        errors: [],
        message: null,
      });
    },
  },
};
</script>
