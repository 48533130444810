<template>
  <div>
    <h1 class="text-2xl font-medium">{{ $t("dashboard.report_overview") }}</h1>
    <div
      class="h-40 flex flex-row pt-4 space-x-4 items-center justify-center"
      v-if="errorMessage != null"
    >
      <p class="text-error">{{ errorMessage }}</p>
      <app-button-outline
        class="h-10 space-x-2 w-auto justify-between"
        @click="getOverview"
      >
        <div
          class="
            place-items-start
            flex flex-col
            text-gray-700
            font-semibold
            antialiased
            text-xs
          "
        >
          <span class="text-gray-700">
            {{ $t("general.try_again") }}
          </span>
        </div>
      </app-button-outline>
    </div>
    <div
      class="
        py-3
        flex flex-col
        justify-between
        md:flex-row md:space-y-0 md:space-x-3
        space-y-3 space-x-0
      "
      v-if="errorMessage == null"
    >
      <p class="border p-2 rounded-md">
        {{ $moment(select_date?.from).format("DD-MM-YYYY") }}
        {{ $t("general.to") }}
        {{ $moment(select_date?.to).format("DD-MM-YYYY") }}
      </p>
      <app-button-select-date
        @select_date="select_date = $event"
        @select_date_format="select_date_format = $event"
        @on_select="getOverview"
      />
    </div>
    <div
      class="grid grid-rows-1 md:grid-cols-2 gap-3 mt-3 text-gray-600"
      v-if="errorMessage == null"
    >
      <app-card-box class="space-y-1 h-60">
        <p class="text-lg font-medium">{{ $t("dashboard.total_sales") }}</p>
        <paragraph-shimmer
          :is-loading="loading"
          :lines="1"
          :random-size="true"
        />
        <p class="text-2xl" v-if="!loading">
          {{ $formats.currency(overview.currency, overview.sales) }}
        </p>
      </app-card-box>
      <app-card-box class="space-y-1 h-60">
        <p class="text-lg font-medium">{{ $t("dashboard.net_volume") }}</p>
        <paragraph-shimmer
          :is-loading="loading"
          :lines="1"
          :random-size="true"
        />
        <p class="text-2xl" v-if="!loading">
          {{ $formats.currency(overview.currency, overview.net_sales) }}
        </p>
      </app-card-box>
    </div>
  </div>
  <div v-if="errorMessage == null">
    <div class="grid grid-rows-1 md:grid-cols-3 gap-3 mt-3 text-gray-600">
      <app-card-box class="space-y-1">
        <p class="text-lg font-medium">{{ $t("dashboard.total_customer") }}</p>
        <paragraph-shimmer
          :is-loading="loading"
          :lines="1"
          :random-size="true"
        />
        <p class="text-2xl" v-if="!loading">{{ overview.total_customers }}</p>
      </app-card-box>
      <app-card-box class="space-y-1">
        <p class="text-lg font-medium">
          {{ $t("dashboard.successful_payment") }}
        </p>
        <paragraph-shimmer
          :is-loading="loading"
          :lines="1"
          :random-size="true"
        />
        <p class="text-2xl" v-if="!loading">
          {{ overview.successful_payments }}
        </p>
      </app-card-box>
      <app-card-box class="space-y-1">
        <p class="text-lg font-medium">
          {{ $t("dashboard.avg_spend_per_customer") }}
        </p>
        <paragraph-shimmer
          :is-loading="loading"
          :lines="1"
          :random-size="true"
        />
        <p class="text-2xl" v-if="!loading">
          {{ $formats.currency(overview.currency, overview.average_spending) }}
        </p>
      </app-card-box>
    </div>
  </div>
</template>

<script>
import { ParagraphShimmer } from "vue3-shimmer";

export default {
  data() {
    return {
      select_date: {},
      select_date_format: null,
    };
  },
  components: {
    ParagraphShimmer,
  },
  computed: {
    overview() {
      return this.$store.getters["dashboardStore/overview"];
    },
    loading() {
      return this.$store.getters["dashboardStore/overviewLoading"];
    },
    errorMessage() {
      return this.$store.getters["dashboardStore/overviewMessage"];
    },
    dateFrom() {
      return this.$store.getters["dashboardStore/dateFrom"];
    },
    dateTo() {
      return this.$store.getters["dashboardStore/dateTo"];
    },
  },
  methods: {
    getOverview() {
      this.$store.dispatch("dashboardStore/retrieveOverviewDashboard", {
        date_between: this.select_date_format,
      });
    },
  },
};
</script>
