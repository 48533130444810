<template>
  <app-layout>
    <!-- Active Account -->
    <div class="bg-white border-b">
      <dashboardVerifyEmaiItem />
    </div>

    <!-- Role - ADMIN -->
    <div class="m-auto" v-if="selectedRole?.role_id == Role.ADMIN">
      <p>Dashboard ADMIN</p>
    </div>

    <!-- Role - OPERATION -->
    <div
      class="container mx-auto my-7 space-y-10 px-5"
      v-else-if="selectedRole?.role_id == Role.OPERATION"
    >
      <operationDashboardTodaySummaryItem />
    </div>

    <!-- Role - ACCOUNT -->
    <div
      class="container mx-auto my-7 space-y-10 px-5"
      v-else-if="selectedRole?.role_id == Role.ACCOUNT"
    >
      <accountDashboardTodaySummaryItem />
      <accountDashboardReportOverviewItem />
    </div>

    <!-- Role - SUPPORT -->
    <div class="m-auto" v-else-if="selectedRole?.role_id == Role.SUPPORT">
      <p>Dashboard SUPPORT</p>
    </div>

     <!-- Role - SALES -->
    <div class="m-auto" v-else-if="selectedRole?.role_id == Role.SALES">
      <p>Dashboard SALES</p>
    </div>

    <!-- Role - MASTER MERCHANT -->
    <div
      class="container mx-auto my-7 space-y-10 px-5"
      v-else-if="
        selectedRole?.role_id == Role.MASTER_BUSINESS_OWNER ||
        selectedRole?.role_id == Role.MASTER_BUSINESS_ADMIN ||
        selectedRole?.role_id == Role.MASTER_BUSINESS_MANAGER ||
        selectedRole?.role_id == Role.MASTER_BUSINESS_DEVELOPER ||
        selectedRole?.role_id == Role.MASTER_BUSINESS_SUPPORT
      "
    >
      <masterBusinessDashboardSummaryItem />
      <masterBusinessDashboardSummaryTotalBusinessItem />
    </div>

    <!-- Role - USER -->
    <div class="container mx-auto my-7 space-y-10 px-5" v-else>
      <dashboardTodaySummaryItem />
      <dashboardReportOverviewItem />
    </div>
  </app-layout>
</template>

<script>
import Role from "@/utils/const/role";

import dashboardVerifyEmaiItem from "./items/dashboard-verify-email-item.vue";
import dashboardTodaySummaryItem from "./items/dashboard-today-summary-item.vue";
import dashboardReportOverviewItem from "./items/dashboard-report-overview-item.vue";
import accountDashboardTodaySummaryItem from "./items/account/dashboard-today-summary-item.vue";
import accountDashboardReportOverviewItem from "./items/account/dashboard-report-overview-item.vue";
import operationDashboardTodaySummaryItem from "./items/operation/dashboard-summary-item.vue";
import masterBusinessDashboardSummaryItem from "./items/master-business/dashboard-summary-item.vue";
import masterBusinessDashboardSummaryTotalBusinessItem from "./items/master-business/dashboard-summary-total-business-item.vue";

export default {
  data() {
    return {
      Role: Role,
    };
  },
  computed: {
    selectedRole() {
      return this.$store.getters["authStore/selectedRole"];
    },
  },
  components: {
    dashboardVerifyEmaiItem,
    dashboardTodaySummaryItem,
    dashboardReportOverviewItem,
    accountDashboardTodaySummaryItem,
    accountDashboardReportOverviewItem,
    operationDashboardTodaySummaryItem,
    masterBusinessDashboardSummaryItem,
    masterBusinessDashboardSummaryTotalBusinessItem,
  },
};
</script>
